:root {
    --indigo: #7733fe;
    --lightIndigo: #be9ffa;
    --grey: #6f6f6f;
    --fontFamily: 'Libre Baskerville', sans-serif;
    --texts: "Design automation", "Digital Twin", "Project Lifecycle", "Asset Management", "Task Management", "Document Control", "Central Database", "Custom Software";
}

.homePage-container {
    background-color: #000000;
    font-family: var(--fontFamily);
    /* display: flex;

    flex-direction: column; */
}

.homePage-container header {
    position: relative;
    top: 0;
    left: 5%;
    width: 90vw;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    border-radius: 35px;
    align-items: center;
    padding: 5px 25px 5px 15px;
    /* margin-top: 10px; */
}

.navElem {
    color: white;
    padding: 0.5em 1.5em 0.5em;
    cursor: pointer;
}

.homePage-container header .login-button {
    color: white;
    padding: 0.5em 1.5em 0.5em;
    border-radius: 9px;
    background-color: #181c1f;
    border: 1px solid #fff;
    cursor: pointer;
}

.homePage-container header img {
    width: 130px;
    height: 70px;
}

.homePage-new {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    min-height: 90vh;
    width: 100%;
    background: #000000;
    /* background-image: url('../images/home_page/bg4.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border-bottom: 3px solid #7733fe; */
}

/* .homePage-new::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30%;
    height: 1px;
    background-color: #000;
    border-bottom: var(--lightIndigo) solid 4px;
    transform: translateX(-50%);

} */

.hero-section {
    display: flex;
    width: 90%;
    /* justify-content: ; */
    align-items: center;
}

@media screen and (max-width: 1100px) {
    .hero-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .hero-section .text-container {
        width: 85vw;
        padding: 0;
    }

    .hero-section .navigation {
        width: 90vw;
        justify-content: center;
    }

    section {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }

    section .leftcard {
        width: 90vw;
        max-width: none;
    }

    section .rightcard {
        width: 90vw;
    }
}


@media screen and (max-width: 1100px) {
    .nav-description-container {
        display: none;
    }
}

.hero-section h5 {
    color: #747474;
    position: relative;
    align-self: flex-start;
    top: 16px;
    right: 160px;
}



/* #Wheel:hover {
    transform: rotate(90deg);
} */

/* @keyframes rotateWheel {

    0%,
    3% {
        transform: rotate(0deg);
    }

    8%,
    12% {
        transform: rotate(-45deg);
    }

    18%,
    21% {
        transform: rotate(-90deg);
    }

    31%,
    35% {
        transform: rotate(-135deg);
    }

    48%,
    50% {
        transform: rotate(-180deg);
    }

    58%,
    60% {
        transform: rotate(-225deg);
    }

    72%,
    75% {
        transform: rotate(-270deg);
    }

    82%,
    85% {
        transform: rotate(-315deg);
    }

    97%,
    100% {
        transform: rotate(-360deg);
    }
} */

/* @keyframes changeText {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
} */

/* #changing-text:after {
    content: '';
    opacity: 0%;
    display: block;
    width: 60px;
    animation: changeText 40s linear infinite;
} */

/* @keyframes changeText {

    0%,
    3% {
        content: "Design automation";
        opacity: 100%;
    }

    4% {
        opacity: 0%;
        content: "Digital Twin";
    }

    11%,
    12% {
        opacity: 100%;
    }

    13% {
        opacity: 0%;
        content: "Project Lifecycle";
    }

    20%,
    21% {
        opacity: 100%;
    }

    22% {
        opacity: 0%;
        content: "Asset Management";
    }

    34%,
    35% {
        opacity: 100%;
    }

    36% {
        opacity: 0%;
        content: "Task Management";
    }

    49%,
    50% {
        opacity: 100%;
    }

    51% {
        opacity: 0%;
        content: "Document Control";
    }

    59%,
    60% {
        opacity: 100%;

    }

    61% {
        opacity: 0%;
        content: "Central Database";
    }

    74%,
    75% {
        opacity: 100%;
    }

    76% {
        opacity: 0%;
        content: "Custom Software";
    }

    84%,
    85% {
        opacity: 100%;
    }

    86% {
        opacity: 0%;
        content: "Design Automation";
    }

    99%,
    100% {
        opacity: 100%;
    }
} */


.text-container {
    position: relative;
    height: 60vh;
    width: 50%;

    /* display: flex; */
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    /* -webkit-box-reflect: below 1px linear-gradient(transparent, transparent,
            transparent, transparent, #0005); */
    font-family: 'Libre Baskerville', sans-serif;
    padding: 0 100px;
}

.text-container h1 {
    font-family: inherit;
    font-size: calc(24px+0.8w);
    font-weight: 100;
    color: #c8c8c8;
}

.text-container h2 {
    color: #fff;
    font-family: 'Libre Baskerville', sans-serif;
    margin: 0px;
    /* font-size: 3rem; */
    font-size: calc(40px + 1vw);
    font-weight: 600;
}

.text-container h2 span {
    /* position: absolute; */
    color: rgba(255, 255, 255, 0.1);
    font-family: 'Libre Baskerville', sans-serif;
    font-size: calc(40px + 1vw);
    animation: displayText 9s 1 forwards;
}

.text-container .hero-description {
    font-family: inherit;
    /* font-size: 18px; */
    font-size: calc(15px + 0.390625vw);

    font-weight: 300;
    line-height: 1.4;
    color: #c8c8c8;
    letter-spacing: 0.5px;

    margin-top: 5%;
}

/* .text-container .hero-description::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 40%;
    width: 40%;
    height: 1px;
    background-color: #000;
    border-bottom: var(--indigo) solid 2px;
    transform: translateX(-50%);
} */

@keyframes displayText {

    0%,
    100% {
        display: inline-block;
    }

    /* 33.33%,
    100% {
        display: none;
    } */

}

.text-container h2 span::before {
    content: attr(data-text);
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100%;
    color: #8f80ef;
    /* color: #003bec; */
    /* color: #7733fe; */
    overflow: hidden;
    /* filter: drop-shadow(0 0 5px #7733fe) drop-shadow(0 0 25px #7733fe); */
    /* filter: drop-shadow(0 0 5px #7733fe); */
    animation: animate 3s 1 forwards;
}

@keyframes animate {

    0%,
    10% {
        width: 0;
    }

    100% {
        width: 100%;
        border-right: none;
    }
}

/* Wheel Navigation start */

#Wheel {
    /* animation: rotateWheel 40s linear infinite; */
    transition: 2s ease-in-out;
    /* transition-delay: 2s; */
    transform-origin: center center;
    transform: rotate(0deg);
}

.navigation {
    display: flex;
    /* background-color: white; */


}

.wheel-navigation {
    flex-direction: row;
    padding: 0 30%;
    justify-content: space-around;
}


.navigation-btns {
    display: flex;
    justify-content: center;
}


.navigation-btns .btn {
    border: 2px solid #76649b;
    padding: 4px;
    border-radius: 10px;
    cursor: pointer;
}

.navigation-btns .btn.active {
    border: 2px solid #be9ffa;
    background-color: #be9ffa;
}

.navigation-btns:not(:last-child) {
    margin-right: 10px;
}

.navigation-btns .btn:hover {
    background-color: #be9ffa;
    border: 2px solid #be9ffa;
}

.navigation-manual {
    /* margin-top: ; */
    display: flex;
    justify-content: center;
}

.navigation-manual .manual-btn {
    border: 2px solid #76649b;
    padding: 4px;
    border-radius: 10px;
    cursor: pointer;
}

.navigation-manual .manual-btn:not(:last-child) {
    margin-right: 10px;
}

.navigation-manual .manual-btn:hover {
    background-color: #be9ffa;
    border: 2px solid #be9ffa;
}

#radio1:checked~.wheel #Wheel {
    transform: rotate(0deg);
}

#radio2:checked~.wheel #Wheel {
    transform: rotate(-45deg);
}

#radio3:checked~.wheel #Wheel {
    transform: rotate(-90deg);
}

#radio4:checked~.wheel #Wheel {
    transform: rotate(-135deg);
}

#radio5:checked~.wheel #Wheel {
    transform: rotate(-180deg);
}

#radio6:checked~.wheel #Wheel {
    transform: rotate(-225deg);
}

#radio6:checked~.wheel #Wheel {
    transform: rotate(-270deg);
}

.nav-description-container {
    height: 60vh;
    width: 200px;
    top: 10px;
    position: relative;
    /* background-color: red; */
}

.nav-description {
    left: 0px;
    text-align: right;
    height: 50px;
    width: 172px;
    position: absolute;
    top: 20px;
    transform: translate(10px, 10px);
}

.nav-description-container .wheel-title {
    color: #8f80ef;
    margin-bottom: 5px;
    position: absolute;
    top: 0;
    text-align: right;
    font-size: calc(12px + 0.390625vw);
    transition: 2s;
    opacity: 0;
    width: 100%;
}

.nav-description-container .wheel-para {
    color: #c8c8c8;
    margin-bottom: 5px;
    position: absolute;
    top: 100px;
    width: 100%;
    font-size: calc(11px + 0.390625vw);
    transition: 1s;
    opacity: 0;
}

.nav-description .active {
    opacity: 1;
}


@keyframes tracking-in-expand {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}


.track-in {
    animation: tracking-in-expand 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    ;
    /* opacity: 100% */
}

.track-out {
    animation: tracking-out-contract .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes tracking-out-contract {
    0% {
        -webkit-filter: blur(0.01);
        filter: blur(0.01);
    }

    100% {
        -webkit-filter: blur(12px) opacity(0%);
        filter: blur(12px) opacity(0%);
    }
}


/* Wheel Navigation End */

.glow-header {
    color: #7733fe;
    filter: drop-shadow(0 0 5px #7733fe);
    font-size: 2.5em;
    font-family: "Libre Baskerville";
    font-weight: 600;
    padding: 0px 0px 20px 0px;
    opacity: 0.5;
}


.homePage-container .second-section {
    position: relative;
    min-height: 90vh;
    width: 100%;
    /* border-top: 5px solid #7733fe; */
    background-color: #000;
    font-family: 'Libre Baskerville', sans-serif;
    color: #fff;
    display: flex;
    /* border-bottom: #7733fe solid 2px; */
}

.homePage-container .second-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30%;
    height: 1px;
    background-color: #000;
    border-bottom: var(--lightIndigo) solid 1px;
    transform: translateX(-50%);

}

.homePage-container section {
    position: relative;
    min-height: 80vh;
    width: 100%;
    /* border-top: 5px solid #7733fe; */
    background-color: #000;
    font-family: 'Libre Baskerville', sans-serif;
    color: #fff;
    display: flex;
}

.leftcard {
    background-color: #000;
    font-family: inherit;
    display: flex;
    /* padding: 0.5em 0.5em; */
    width: 50%
}

.leftcard .leftcard-content {

    /* font-family: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ; */

    width: 70%;
    max-width: 430px;
    flex: 0 auto;
    align-self: auto;
    /* align-items: center; */
    margin-top: 0;
    margin-left: 15%;
    padding-top: 0;
    /* display: block; */
    /* font-family: inherit; */

    display: flex;
    font-family: inherit;
    flex-direction: column;
    justify-content: center;
    /* font-size: 3em;
    font-family: inherit;
    font-weight: 400; */
}

.leftcard .leftcard-content .section-title {
    font-size: 3em;
    font-family: inherit;
    font-weight: 400;
    color: #fff;
    /* margin-top: 35px; */
    margin-bottom: 28px;
}

.leftcard .leftcard-content h2 span {
    font-family: inherit;
}

.leftcard .leftcard-content p {
    font-family: inherit;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    color: #c8c8c8;
    letter-spacing: 1px;
    /* font-size: 3em;
    font-weight: 400;
    margin-top: 35px;
    margin-bottom: 28px; */
}

.leftcard .leftcard-content .read-more {
    margin-top: 30px;
    width: fit-content;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: var(--indigo)
}

.rightcard {
    /* background-color: blue; */
    width: 50%;
    display: flex;
}

.rightcard .rightcard-content {
    width: 70%;
    max-width: 430px;
    flex: 0 auto;
    align-self: auto;
    margin-top: 0;
    padding-bottom: 100px;
    margin-left: 15%;
    padding-top: 0;

    display: flex;
    font-family: inherit;
    align-items: center;

    gap: 50px;

}

.rightcard .rightcard-content img {
    width: 200px;
    height: 200px;

    /* position: absolute; */
    backface-visibility: hidden;
    transition: 1s;
    z-index: 2;

    /* animation: flipanimate 2s linear infinite */
}

.rightcard .rightcard-content .form_img {
    position: relative;
    /* top:0px; */
}

.rightcard .rightcard-content .cube_img {
    position: relative;
    top: 100px;
    left: 25px;

    /* opacity: 0; */
    /* Initially set to fully transparent */
}

@keyframes revealAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#arrow {
    position: absolute;
    width: 2px;
    top: 400px;
    height: 20px;
    background-color: red;
    transition: height 1s, transform 1s;
    animation: moveArrow 2s linear infinite;
}

.arrow-box {
    position: relative;
    min-width: 172px;
    min-height: 100px;
    top: 100px;
    right: 330px;
    z-index: 1;
    /* background-color: white; */
    border-left: 3px double var(--lightIndigo);
    border-bottom: 3px double var(--lightIndigo);

    /*  */
    /* box-shadow: 16px 14px 20px #0000008c;
    display: flex;
    justify-content: center;
    align-items: center; */
    /*  */
}

/* .arrow-box::before {
    content: "";
    width: 172px;
    height: 100px;
    background: linear-gradient(270deg,#cc6aa5,#3e91cc,#2dcca7);
    background-position: 50% 100%;
    animation: bg-pan-top 9s linear infinite;
} */

@keyframes bg-pan-top {
    0% {
        background-position: 50% 100%;
        /* transform: rotate(360deg); */
    }

    100% {
        background-position: 50% 0%;
    }
}


.arrow-box::after {
    content: "";
    width: 172px;
    height: 180px;
    position: relative;
    /* bottom: 5px; */
    left: -2px;
    background: #000;
    /* background: #fff; */
    position: absolute;
    animation: revealLinear 5s infinite;
}

@keyframes revealLinear {
    0% {
        /* background-position: 50% 100%; */
        transform: translateX(-2px);
    }

    50% {
        transform: translateX(0px) translateY(96px);

    }

    100% {
        transform: translateX(150px) translateY(96px);
        /* background-position: 50% 0%; */
    }
}

.form_img::before {
    content: "";
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    /* color: #8f80ef; */
    /* color: #003bec; */
    color: #7733fe;
    overflow: hidden;
    /* filter: drop-shadow(0 0 5px #7733fe) drop-shadow(0 0 25px #7733fe); */
    filter: drop-shadow(0 0 5px #7733fe);
}

.third-section {
    position: relative;
    min-height: 90vh;
    width: 100%;
    /* border-top: 5px solid #7733fe; */
    background-color: #000;
    font-family: 'Libre Baskerville', sans-serif;
    color: #fff;
    display: flex;
}

.third-section .tasklist {
    position: relative;
    left: 50px;
    top: 20px;
    width: 190px;
    height: 210px;

    background-color: grey;
    background: linear-gradient(180deg, #595757 0%, #747474 100%);
    /* border: 1px solid var(--lightIndigo); */
    /* display: flex; */
}

.third-section .tasklist .checked {
    width: 40px;
    height: 40px;
    /* border-radius: 50px; */
    border: 2px solid var(--lightIndigo);
    margin: 15px;
    animation: rotateAnimation 4s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    15% {
        transform: rotate(360deg);
        background-color: var(--lightIndigo);
    }

    50% {
        background-color: transparent;
    }

    100% {
        transform: rotate(360deg);

    }
}

.tasklist .task {
    display: flex;
    align-items: center;
}

.third-section .tasklist .line {
    width: 80px;
    height: 4px;
    border: 1px solid var(--lightIndigo);
    margin: 15px;
    /* animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
    animation: fade-in-left 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite;
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    20% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}



.third-section .tasklist .checked::after {
    content: '\2713';
    /* Unicode character for checkmark */
    font-size: 24px;
    /* color: var(--lightIndigo); */
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: fadeInOut 4s infinite;
    /* Adjust the animation delay as needed */
}

@keyframes fadeInOut {

    0%,
    50%,
    100% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }
}

.third-section .tasklist .unchecked {
    width: 40px;
    height: 40px;
    /* border-radius: 50px; */
    border: 2px solid var(--lightIndigo);
    margin: 15px;
}

/* .third-section .tasklines {
    position: relative;
    width: 190px;
    height: 200px;
    border: 2px solid var(--lightIndigo);
} */

/* .rightcard-content img:nth-child(2) {
    transition:1s;
    animation: flipanimate 5s  infinite
}
.rightcard-content img:nth-child(1) {
    transition:1s;
    animation: flipanimateopp 5s  infinite
} */

/* @keyframes flipanimate {
    0%{
        transform: rotateX(0deg) rotateY(180deg)
    }
    100%{
        transform: rotateX(0deg) rotateY(0deg)
    }
}

@keyframes flipanimateopp {
    0%{
        transform: rotateX(0deg) rotateY(0deg)
    }
    100%{
        transform: rotateX(0deg) rotateY(-180deg)
    }
} */

/* .rightcard-content img:nth-child(2) {
    transform: rotateY(180deg);
}

.rightcard-content:hover img:nth-child(2) {
    transform: rotateY(0deg);
}

.rightcard-content:hover img:nth-child(1) {
    transform: rotateY(-180deg);
} */