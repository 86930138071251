.home {
    /* Container 185 */
    position: absolute;
    top: 68px;
    left: 0px;
    width: 1440px;
    height: 560px;
    background: #F3F2FDFF;
    border-radius: 0px;
}
.main-text{

    font-family: Open Sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    /* color: #2E1164FF; */

}
.text{
        font-family: Open Sans;
        font-weight: 400;
        line-height: 22px;
        color: #565D6DFF;
}

/* Button 33 */
.button {
      position: absolute; 
      top: 370px; 
      left: 123px; 
      width: 192px; 
      height: 52px; 
      padding: 0 20px; 
      display: flex; 
      align-items: center; 
      justify-content: center; 
      font-family: Open Sans; 
      font-size: 18px; 
      font-weight: 400; 
      line-height: 28px; 
      color: #FFFFFFFF; 
      background: #2E1164FF; 
      opacity: 1; 
      border: none; 
      border-radius: 4px; 
      box-shadow: 0px 4px 9px #2e1164, 0px 0px 2px #2e1164;
    }
    /* Hover */
    .button:hover {
      color: #FFFFFFFF; 
      background: #41188DFF; 
    }
    /* Pressed */
    .button:hover:active {
      color: #FFFFFFFF; 
      background: #531FB5FF; 
    }
    /* Disabled */
    .button:disabled {
      opacity: 0.4; 
    }

    /* Avatar 49 */
.avatar49 {
      position: absolute; 
      top: 182px; 
      left: 679px; 
      width: 68px; 
      height: 68px; 
      font-family: Open Sans; 
      font-size: 34px; 
      font-weight: 400; 
      line-height: 34px; 
      color: #FFFFFFFF; 
      background: #453F6CFF; 
      opacity: 1; 
      overflow: hidden; 
      border-radius: 34px; 
    }
    .avatar49 .badge {
      width: 17px; 
      height: 17px; 
      border-radius: 8.5px; 
    }
    .avatar49.active .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar49.inactive .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar49.idle .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar49.do_not_disturb .badge {
      display: flex; 
      align-items: center; 
      justify-content: center; 
      font-size: 17px; 
      line-height: 17px; 
      color: #FFFFFFFF; 
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    /* Image 252 */
.image {
      position: absolute; 
      top: 79px; 
      left: 786px; 
      width: 402px; 
      height: 402px; 
      border-radius: 0px; 
    }
    /* Avatar 50 */
.avatar50 {
      position: absolute; 
      top: 136px; 
      left: 1227px; 
      width: 92px; 
      height: 92px; 
      font-family: Open Sans; 
      font-size: 46px; 
      font-weight: 400; 
      line-height: 46px; 
      color: #FFFFFFFF; 
      background: #6821F9FF; 
      opacity: 1; 
      overflow: hidden; 
      border-radius: 46px; 
    }
    .avatar50 .badge {
      width: 23px; 
      height: 23px; 
      border-radius: 11.5px; 
    }
    .avatar50.active .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar50.inactive .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar50.idle .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar50.do_not_disturb .badge {
      display: flex; 
      align-items: center; 
      justify-content: center; 
      font-size: 23px; 
      line-height: 23px; 
      color: #FFFFFFFF; 
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }

    /* avatar 51 */
.avatar51 {
      position: absolute; 
      top: 410px; 
      left: 1124px; 
      width: 92px; 
      height: 92px; 
      font-family: Open Sans; 
      font-size: 46px; 
      font-weight: 400; 
      line-height: 46px; 
      color: #FFFFFFFF; 
      background: #6A5FACFF; 
      opacity: 1; 
      overflow: hidden; 
      border-radius: 46px; 
    }
    .avatar51 .badge {
      width: 23px; 
      height: 23px; 
      border-radius: 11.5px; 
    }
    .avatar51.active .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar51.inactive .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar51.idle .badge {
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    .avatar51.do_not_disturb .badge {
      display: flex; 
      align-items: center; 
      justify-content: center; 
      font-size: 23px; 
      line-height: 23px; 
      color: #FFFFFFFF; 
      background: #000000FF; 
      opacity: 0; 
      border-width: 1.5px; 
      border-color: #FFFFFFFF; 
    }
    /* Rectangle 24 */
.rectangle {
      position: absolute; 
      top: 133px; 
      left: 693px; 
      width: 584px; 
      height: 312px; 
      background: #A49AEDFF; 
      border-radius: 156px; 
    }