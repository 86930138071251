/* body, html {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: ArtifaktElement;
} */

#autodesk-div {
  position: relative;
  width: 1000px;
  height: 80vh;
}

#autodesk-preview,
#autodesk-overlay {
  position: fixed;
  /* bottom: "0px"; */
  /* top: 100px; */
  width: 1000px;
  height: 60%;
}

#autodesk-header {
  height: 3em;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

/* #autodesk-preview,
#autodesk-overlay {
    top: 3em;
    bottom: 50px;
} */

/* canvas {
    width: "800px"
} */

#autodesk-overlay {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  /* padding: 1em; */
  display: none;
}

#autodesk-overlay > .autodesk-notification {
  margin: auto;
  padding: 1em;
  max-width: 50%;
  background: white;
}

#autodesk-header > * {
  height: 2em;
  margin: 0 0.5em;
  font-size: 1em;
  font-family: ArtifaktElement;
}

#autodesk-header .autodesk-title {
  flex: 1 0 auto;
  height: auto;
}

#autodesk-models {
  flex: 0 1 auto;
  min-width: 2em;
}
